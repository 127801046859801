import React from 'react';
import { graphql } from 'gatsby';
import { Paper, Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { MdxArticle } from '../types/Article';
import Layout, { Wrapper } from '../layouts/Layout';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { Footer } from '../components/Footer';
import { Branding } from '../components/Branding';
import { ProductHuntBanner } from '../components/ProductHuntBanner';

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(8)}px;

  & > div {
    margin-bottom: 12px;
  }
`;

const COLOR = '#ffffff';

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${COLOR};
    border-bottom: 2px solid #efefef;
  `};
`;

const StyledA = styled('a')`
  color: ${COLOR};
  border-bottom: 2px solid #efefef;
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Impressum"
        siteUrl="https://bloggingfordevs.com"
        description="Impressum"
        pathname="impressum/"
      />
      <ProductHuntBanner />
      <div
        style={{
          maxWidth: '500px',
          padding: '16px',
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <Wrapper>
          <Branding to="/">Blogging for Devs</Branding>
          <Typography variant="h4" component="h1" gutterBottom>
            Impressum
          </Typography>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8 }}
          >
            Last updated: July 27, 2020
            <br />
            <br />
            Monica Lent
            <br />
            c/o Factory Works GmbH
            <br />
            Lohmühlenstraße 65, 12435 Berlin
            <br />
            <br />
            <StyledA href="mailto:hi@bloggingfordevs.com">
              hi@bloggingfordevs.com
            </StyledA>
            <br />
            <br />
            <strong>Disclaimer</strong>
            <br />
            This document contains the information required for an Impressum.
            For more information about how your data is used, collected, and
            stored, please read the <StyledLink to="/terms/">terms</StyledLink>.
            <br />
            <br />
            <strong>Liability for content</strong>
            <br />
            The content of this website was created with great care. I do my
            best to it ensure that it is accurate, but it is possible that it is
            or becomes outdated. Please verify any information provided on this
            website before acting upon it. You may contact me if you find the
            information is inaccurate or outdated so that I can revise it.
            <br />
            <br />
            <strong>Liability for links</strong>
            <br />
            This website has lots of links to other websites, and as such I am
            not liable for the content of those websites. Rather, the respective
            provider of that website may be. I do check the sites I link at the
            time, but if you find a problem with a site I've linked, you may
            contact me so I can remove or replace it.
            <br />
            <br />
            <strong>Copyright</strong>
            <br />
            All content on this website is copywritten under German law, unless
            otherwise noted. Reproduction, adaptation, distribution, or any kind
            of exploitation outside the limits of copyright require prior
            written consent of the author. Downloads and copies of these pages
            are only permitted for private, non-commercial use. If you believe
            that a copyright infringement has occurred on this website, please
            notify us immediately.
          </Typography>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default IndexPage;
